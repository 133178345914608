/* Global - Style Overrides */

/**
router-outlet {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
}**/

body {
	overflow: hidden !important;
	position: relative;
}

:focus:not(:focus-visible) {
	outline: none
}

.hidden {
	display: none !important;
}

.h1, h1 {
	font-size: 39px;
}

.flex {
	display: flex;
}

.flex>* {
	flex: 1 0 auto;
	display: inline-flex;
	align-items: center;
}

.ng-value-icon {
	margin-right: 5px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .ng-value-icon {
	display: inline-block;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
	cursor: pointer;
}

.ng-dropdown-panel {
	background-color: rgb(255, 255, 255, 0.9);
	position: absolute !important;
	margin: 10px 0 0 0;
	padding: 0 !important;
	left: 0px;
	border: 2px solid rgba(230, 230, 230, 1);
	border-radius: 4px;
	text-align: left;
	font-size: 0.9em;
	top: 0;
	left: 0;
	z-index: 9999 !important;
}

.ng-dropdown-panel .ng-option:first-child {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.ng-dropdown-panel .ng-option:last-child {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.ng-option {
	line-height: 30px;
	padding-left: 10px;
}

.ng-option:hover {
	background-color: rgb(0, 0, 0, .4);
	color: white;
}

.ng-option-selected {
	color: rgb(0, 0, 0, .25);
}

/* Custom Bootstrap Classes */

.row {
	width: 100%;
}

.container {
	padding: 15px;
	margin: 0;
}

.quick-links .container.empty {
	background: unset !important;
	border: 0 !important;
}

/* Media Width Overrides */

@media (min-width: 1200px) {
	.container {}
}

.nav-pills>li+li {
	margin-left: 0;
}

/* Global - Custom Classes */

.red {
	color: red;
}

.green {
	color: green;
}

.orange {
	color: orange;
}

.bold {
	font-weight: bold;
}

.right {
	text-align: right;
	float: right;
}

.center {
	text-align: center;
}

/* Global - Loading Indicator Styles */

@keyframes rotateLoader {
	0% {
		transform: rotate(0.0001deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loading.in:before {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	border: 1px solid transparent;
	content: "";
	position: absolute;
	z-index: 1001;
	transition: .4s ease all;
}

.loading.in:after, .loading.in:before {
	content: "";
	position: absolute;
	transition: all .4s ease;
}

.loading.in:after {
	left: 50%;
	top: calc(50% - 30px);
	margin-left: -30px;
	height: 60px;
	width: 60px;
	border: 10px solid #a1d8fc;
	border-top-color: #aaa7de;
	box-shadow: 0 0 .25em #0c194b;
	border-radius: 50%;
	animation: rotateLoader 1s infinite linear;
	content: "";
	position: absolute;
	z-index: 1002;
	transition: .4s ease all;
	background-color: rgba(12, 25, 75, .4);
}

/* Global - Layout Styles */

.main-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background: white;
	overflow: hidden;
	/*
    background: rgb(69,69,69);
    background: -moz-linear-gradient(180deg, rgba(69,69,69,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(69,69,69,1) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(69,69,69,1) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#454545",endColorstr="#ffffff",GradientType=1);
    */
}

.main-container .main-content-wrapper {
	/*flex: 1 1 auto;*/
	display: flex;
	background: #f3f5f6;
	position: relative;
	min-height: 60px;
}

.main-container .main-content-wrapper .main-content {
	padding: 0 15px 15px;
	width: 100%;
}

.main-container .main-content-wrapper>* {
	flex: 1 1 auto;
}

.main-container .main-content-wrapper .main-content .main-content-header {
	background: #fff;
	margin: 50px -15px 0px -15px;
	position: fixed;
	z-index: 1029;
	width: 100%;
	height: 60px;
	padding: 10px 15px;
	display: flex;
	align-items: center;
	box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.main-container .main-content-wrapper .main-content .main-content-header h1 {
	font-size: 1.3em;
	margin: 0;
	flex: 1 0 auto;
}

.main-content-area {
	padding: 0;
}

.main-content-area .row.page {
	border: 1px dashed #C8CCD4;
}

.quick-links.row {
	margin-top: 15px;
	margin-bottom: 15px;
	--bs-gutter-x: 0;
}


/* Global - Page Styles */

img.page-icon {
	width: 120px;
	height: 120px;
	float: left;
	margin-right: 40px;
	margin-top: 30px;
}

i.page-icon {
	width: 114px;
	height: 114px;
	float: left;
	margin-right: 43px;
	margin-top: 33px;
	border-radius: 50%;
	background-color: rgb(247, 251, 255);
	line-height: 114px;
	text-align: center;
	font-size: 3em;
	margin-left: 3px;
	color: rgb(46, 92, 184);
	text-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
}

.page-title.welcome {
	margin-bottom: 35px;
	margin-top: 30px;
	line-height: 120px;
	font-size: 3em;
	font-weight: bold;
	color: white;
	letter-spacing: -1px;
	text-align: center;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, .7);
}

.page-title.welcome.left {
	text-align: left;
}

.page-title.welcome.center {
	margin-bottom: 0;
	line-height: 60px;
	margin-top: 60px;
}

.page-title.welcome i {
	font-size: .7em;
	position: inherit;
	color: #555;
}

.page-title.message {
	margin-bottom: 50px;
	margin-top: 0;
	font-size: 1.1em;
	line-height: 30px;
	color: white;
	font-weight: 400;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, .7);
}

.page-title.message.center {
	font-weight: 400;
	margin-bottom: 20px;
	color: rgb(0, 0, 55);
	margin-top: 10px;
}

.page-title a:hover {
	cursor: pointer;
}

/**
.page-title {
	font-size: 2.5em;
	margin-top: 5px;
	margin-bottom: 30px;
	font-weight: 100;
	line-height: 55px;
}

.private-mode .page-title {
	color: rgb(225, 225, 225);
}

.page-title img {
	height: 55px;
	margin-top: -10px;
}

.page-title i {
	font-size: .6em;
	position: absolute;
	color: rgba(210, 210, 210, 1);
}

.page-title i.active {
	color: green;
}

.page-title i:hover {
	color: green;
	cursor: pointer;
}

.page-title i.active:hover {
	color: rgba(210, 210, 210, 1);
	;
	cursor: pointer;
}

.help-text {
	margin-bottom: 30px;
	font-size: 1.3em;
	font-weight: 300;
}
**/


/* Global - Modal Styles */

.modal-backdrop.in {
	opacity: .9 !important;
}

body.modal-open .modal {
	display: flex !important;
	height: 100%;
}

body.modal-open .modal .modal-dialog {
	margin: auto;
}

@media (min-width: 992px) {
	.modal-lg, .modal-xl {
		width: 800px;
		max-width: 800px;
	}
}

@media (min-width: 1200px) {
	.modal-lg, .modal-xl {
		width: 1000px;
		max-width: 1000px;
	}
}

.modal.fade.show .modal-content {
	animation-name: zoom;
	animation-duration: 0.6s;
}

.modal.fade .modal-content {
	-webkit-animation-name: zoom;
	animation-name: zoom;
}

@keyframes zoom {
	from {
		transform: scale(0)
	}

	to {
		transform: scale(1)
	}
}

.modal-content {
	padding: 0;
	background-color: transparent;
	border: none;
	border-radius: 5px;
	box-shadow: none;
}

.event .modal-content {
	background-color: transparent;
	height: 75vh;
}

.modal-header {
	background-color: white;
	border-bottom: none;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding: 20px;
}

.modal-title {
	font-weight: bold;
	font-size: 1.5em;
}

.modal-title i, .modal-title em {
	line-height: 32px;
	font-size: 1.4em;
	vertical-align: text-top;
}

.modal-body {
	background-color: white;
	/* display: inline-block; */
	width: 100%;
	padding: 0 20px 20px 20px;
}

.modal-footer {
	background-color: rgb(205, 205, 205);
	border-top: 2px solid rgb(155, 155, 155);
	padding: 10px 20px;
}

.modal-footer button {
	margin: 0 0 0 10px;
}

/* Global - Alerts */

/** THERE IS AN ISSUE WHEN POSITION = FIXED WHERE YOU CANT CLICK OR HOVER A TOASTR **/

.container-alerts {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9999;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	text-align: center;
}

.alert {
	padding: 0;
	margin: 0;
	border-radius: 5px;
	height: 50px;
	line-height: 48px;
}

.alert-success {
	color: #222;
	box-shadow: 0 0 5px white;
}

.alert-danger {
	box-shadow: 0 0 5px #ebccd1;
}

.alert .fas, .alert .far {
	padding: 0 3px 0 15px;
}

.toast-progress {
	position: relative;
	left: 0;
	bottom: 5px;
	height: 6px;
	background-color: rgba(0, 0, 0, .2);
	opacity: 1;
	margin-bottom: 10px;
	border-radius: 5px;
}

.toast-message {
	padding: 0 10px;
}

/** Global - Form Styles **/
ng-dropdown-panel.typeahead {
	height: auto;
}

.ng-select-focused {
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .1) !important;
}

.typeahead {
	min-height: 32px;
	border-radius: 4px;
	padding: 0 6px 0 2px;
	box-shadow: none;
	background-color: rgb(255, 255, 255, 0.9);
	border: 1px solid rgba(46, 92, 184, .3);
	color: #666;
	line-height: 34px;
	margin-bottom: 7px;
	display: inline-table;
}

#classifyTab .typeahead {
	display: block;
}

.typeahead .ng-select-container {
	height: 36px;
}

.view .ng-value {
	padding: 0 !important;
}

.view .ng-value>div {
	padding: 0 8px !important;
}

.typeahead .ng-value {
	background-color: rgba(46, 92, 184, 1);
	color: white;
	line-height: 32px;
	height: 32px;
	margin: 2px 2px 2px 0;
	font-size: .9em;
	border-radius: 3px;
	padding: 0 8px;
}

.typeahead .ng-value>div {
	border-radius: 3px;
	height: 32px;
	line-height: 32px;
}

.typeahead .ng-value i.fa-undo {
	color: darkred;
	background-color: rgb(255, 255, 255);
	height: 20px;
	width: 20px;
	text-align: center;
	line-height: 20px;
	border-radius: 50%;
	font-size: .8em;
	margin-left: 6px;
	opacity: .5;
}

.typeahead .ng-value i:hover {
	opacity: 1;
	cursor: pointer;
}

.typeahead .ng-value>div.suggestion {
	background-color: darkorange;
}

.typeahead .ng-value>div.suggestion-remove {
	background-color: darkorange;
}

.typeahead .ng-value>div.suggestion-new {
	background-color: darkorange;
}

.typeahead .ng-value>div.suggestion-db {
	background-color: darkorange;
}

.content-container .typeahead .ng-value, .vaults-container .typeahead .ng-value {
	height: 32px;
	line-height: 32px;
}

.typeahead .ng-input {
	line-height: 28px;
	padding-left: 10px;
	margin: 5px;
}

.typeahead .ng-arrow-wrapper {
	width: 12px;
}

.typeahead .ng-clear-wrapper {
	width: 16px !important;
	text-align: center;
}

.form-control {
	height: 38px;
	border-radius: 4px;
	padding: 0 8px;
	box-shadow: none;
	background-color: rgb(255, 255, 255, 0.9);
	border: 1px solid rgba(46, 92, 184, .3);
	color: #666;
	line-height: 38px;
	margin-bottom: 7px;
}

.form-control:focus {
	border: 1px solid rgba(46, 92, 184, .3);
	box-shadow: 0 0 0 .25rem rgba(46, 92, 184, .1);
}

.form-control.ng-touched.ng-invalid {
	border: 1px solid hsla(7, 100%, 40%, .2);
	box-shadow: 0 0 0 .25rem hsla(7, 100%, 40%, .1);
}

.filter-container .ng-value {
	margin: 3px 3px 3px 0;
	line-height: 25px;
	height: 25px;
	padding: 0 10px 0 10px;
	background-color: rgb(46, 92, 184);
}

.content-container .fields .ng-value, .metadata-container .ng-value {
	border-radius: 3px;
	margin: 2px 2px 2px 0;
	padding: 0 10px 0 8px;
}

.filter-container .ng-input, .metadata-container .ng-input {
	line-height: 25px;
	padding-left: 0px;
	margin: 3px;
	height: 25px;
}

.filter-container .ng-clear-wrapper {
	line-height: 31px;
}

.content-container .fields .ng-clear-wrapper, .metadata-container .ng-clear-wrapper {
	line-height: 34px;
	height: 34px;
	color: rgba(102, 102, 102, .7);
}

.content-container .fields .ng-clear-wrapper:hover, .metadata-container .ng-clear-wrapper:hover {
	color: rgba(102, 102, 102, 1);
}

.ng-select .ng-arrow-wrapper .ng-arrow {
	opacity: .7;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
	opacity: 1;
}


.content-container .fields .ng-input {
	line-height: 30px;
	margin: 2px;
	padding: 0;
	height: 30px;
}

/** Radio Box Styles **/

.radio-container label {
	display: inline-block;
	font-family: sans-serif, Arial;
	font-size: 1rem;
	box-shadow: none;
	border-radius: 4px;
	padding: 0 12px;
	box-shadow: none;
	background-color: rgba(46, 92, 184, .05);
	border: 1px solid rgba(46, 92, 184, .3);
	color: #666;
	height: 38px;
	line-height: 38px;
	margin: 0 7px 7px 0;
}

.radio-container label:hover {
	background-color: rgba(46, 92, 184, 1);
	color: white;
	cursor: pointer;
}

/** Checkbox styles **/

.switch {
	position: relative;
	display: inline-block;
}

.switch-input {
	display: none;
}

.switch-label {
	display: block;
	width: 48px;
	text-indent: 0%;
	clip: rect(0 0 0 0);
	color: transparent;
	user-select: none;
	margin-bottom: 0;
}

.switch-label::before, .switch-label::after {
	content: "";
	display: block;
	position: absolute;
	cursor: pointer;
}

.switch-label::before {
	width: 100%;
	height: 100%;
	background-color: #dedede;
	border-radius: 9999em;
	-webkit-transition: background-color 0.25s ease;
	transition: background-color 0.25s ease;
}

.switch-label::after {
	top: -2px;
	left: 0;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
	-webkit-transition: left 0.25s ease;
	transition: left 0.25s ease;
}

.switch-input:checked+.switch-label::before {
	background-color: #777;
}

.switch-input:checked+.switch-label::after {
	left: 24px;
}

/** Photos view - Image Slider **/

.ng-image-slider {
	height: 100% !important;
	max-height: 100% !important;
}

.ng-image-slider .ng-image-slider-container .main {
	background-color: #000;
	max-height: 100%;
}

.ng-image-slider .ng-image-slider-container {
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	height: 100%;
	background: black;
}

.col-md-4 .ng-image-slider .ng-image-slider-container .main .main-inner .img-div {
	background-color: #000;
	border-radius: 0 !important;
}

.col-md-4 .ng-image-slider .ng-image-slider-container .main .main-inner .img-div:hover {
	cursor: pointer;
}

.col-md-4 .ng-image-slider .ng-image-slider-container .main .main-inner .img-div img {
	border-radius: unset !important;
	height: auto !important;
}

/** Gallery View - Image Slider **/

.ng-image-slider .ng-image-slider-container .main .main-inner .img-div {
	background-color: #000;
	border-radius: 0 !important;
	margin-left: 0 !important;
	right: 1px;
}

.ng-image-slider .ng-image-slider-container .main .main-inner .img-div img {
	border-radius: unset !important;
}

/** Filter Form Style for Search Page **/

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
	position: inherit !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
	padding: 0 5px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
	overflow: unset !important;
}

/** File Input Styles **/

.custom-dropzone {
	border-radius: 0 !important;
	color: rgba(0, 0, 0, .3) !important;
	border: 2px dashed rgba(46, 92, 184, .3) !important;
	margin: 15px 15px 15px 0;
	height: calc(100vh - 90px) !important;
	flex-wrap: wrap;
	padding: 10px;
	justify-content: normal;
	align-content: flex-start !important;
}

.custom-dropzone ngx-dropzone-label.full {
	height: 100%;
	margin: 0;
	justify-content: center;
	display: flex;
	width: 100%;
	align-items: center;
}

.custom-dropzone ngx-dropzone-label.full .text {
	height: 45px;
	line-height: 45px;
}

.custom-dropzone .upload-row {
	height: 110px !important;
	min-height: 110px !important;
	max-height: 110px !important;
	justify-content: left !important;
	margin: 0 5px 5px 0 !important;
	border: 0 !important;
	padding: 0 !important;
	align-items: normal !important;
}

.custom-dropzone .upload-row img {
	object-fit: contain;
	background-color: rgba(0, 0, 0, 1);
	object-position: center;
	min-width: 175px;
	max-width: 175px;
	height: 110px;
}

.custom-dropzone .upload-row.error img {
	border: 3px solid red;
}

.custom-dropzone .upload-row ngx-dropzone-label.photo-details {
	color: white;
	text-shadow: 0px 0px 3px black;
	font-size: .8em;
	font-weight: 900;
	left: 0px;
	bottom: 5px;
	text-align: left;
	white-space: nowrap;
	margin: 0;
	padding: 0 5px;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.custom-dropzone .upload-row ngx-dropzone-label.photo-details span {
	padding: 3px;
	display: block;
}


.custom-dropzone .upload-row ngx-dropzone-label.photo-details .loading-container {
	width: 100%;
	background: rgba(255, 255, 255, .5);
	height: 12px;
	border-radius: 5px;
	padding: 2px;
	border: 1px solid rgba(0, 0, 0, .5)
}

.custom-dropzone .upload-row ngx-dropzone-label.photo-details .loading-container .indicator {
	width: 0;
	height: 6px;
	background-color: rgba(46, 92, 184, 1);
	border-radius: 4px;
}

.custom-dropzone .upload-row.success ngx-dropzone-label.photo-details .loading-container .indicator {
	background-color: green;
	width: 100%;
}

.custom-dropzone .upload-row.finishing ngx-dropzone-label.photo-details .loading-container .indicator {
	background-color: orange;
	width: 100%;
}

.custom-dropzone .upload-row.error ngx-dropzone-label.photo-details .loading-container .indicator {
	background-color: red;
	width: 100%;
}


.custom-dropzone.ngx-dz-hovered {
	border: 2px dashed rgba(12, 104, 12, 0.6) !important;
}

/**
.custom-dropzone ngx-dropzone-image-preview {
	border: 5px solid rgba(0, 0, 0, 0);
	border-radius: 0 !important;
}

.custom-dropzone ngx-dropzone-image-preview.error img {
	border: 5px solid red;
}

.custom-dropzone ngx-dropzone-image-preview.success img {
	border: 5px solid green;
}
**/
.gallery-inner-dialog:hover {
	cursor: pointer;
}

.masonry-item i.user-image {
	width: 45px;
	height: 45px;
	background-color: rgba(230, 230, 230, .75);
	line-height: 41px;
	color: #000;
	border-radius: 50%;
	text-align: center;
	position: absolute;
	bottom: 7px;
	left: 7px;
	font-size: 1.4em;
	border: 2px solid rgba(0, 0, 0, .75);
}

.masonry-item i.user-image:hover {
	background-color: rgba(230, 230, 230, 1);
	cursor: pointer;
}

app-photo-view #pills-tabContent {
	min-height: 425px;
}

/** TODO: Only if the dashboard is displayed **/
app-dashboard {
	overflow: hidden !important;
	height: calc(100vh - 60px) !important;
}

app-dashboard .scroll {
	overflow-y: auto;
	height: calc(100vh - 60px);
}

app-dashboard>.row {
	overflow-y: auto;
	height: calc(100vh - 60px);
}

.widget-outer, app-dashboard, app-dashboard-widget, app-widget-welcome-message, app-widget-gallery-uncategorized-photos, app-widget-connections-quick-links, app-widget-photos-quick-links, app-widget-vaults-quick-links {
	display: block;
	height: 100%;
	width: 100%;
}

app-widget-photos-my-uncategorized-graph, app-widget-photos-connections-graph, app-widget-photos-uploaded-graph, app-widget-photos-my-uploaded-graph, app-widget-vault-storage-graph {
	display: flex;
	height: 100%;
	width: 100%;
}

gridster-item {
	background: unset !important;
}

.modal-body .scroll-container {
	max-height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
	padding-right: 15px;
}

.dropdown-container ng-select .ng-value {
	background-color: unset;
	color: #555;
	padding: 0 10px;
	border-radius: 25px;
	/**
	height: 28px;
	line-height: 28px;
	
	margin: 4px 4px 4px 0;
	**/
	font-size: .9em;

	width: 100%;
	text-align: left;
}

.dropdown-container ng-select .ng-input {
	width: 70px;
}



.ng-arrow {
	background-size: contain;
	background-image: url('assets/images/icons/arrow-down.svg');
	background-repeat: no-repeat;
	height: 12px !important;
	width: 17px !important;
	display: inline-block !important;
	right: 5px;
	position: relative !important;
	top: 2px;
}

.ng-arrow.up {
	rotate: 180deg;
	top: 0px;
}

.left-container .ng-arrow {
	right: 2px;
}

.ng-select .ng-dropdown-panel {
	top: auto;
	left: auto;
}

/** WHY IS THIS ENABLED **/
.left-container .fields .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper,
.left-container .fields .ng-select.ng-select-clearable .ng-select-container.ng-has-value .ng-arrow-wrapper {
	display: none;
}

/**
.left-container .fields.dropdown-container .ng-select-container.ng-has-value .ng-arrow-wrapper {
	display: inline-block;
}**/

.ng-select-container .ng-clear-wrapper {
	margin-right: 2px;
	font-weight: 600;
}

.ng-select-container .ng-arrow-wrapper {
	/**display: none;**/
	margin-right: 5px;
}


/** Private Mode - Header **/

.private-mode .logo.default {
	display: none;
}

.logo.private {
	display: none;
}

.private-mode .logo.private {
	display: inline-block;
}

.nav-link:focus-visible {
	outline: none;
}

.nav-link>a {
	color: #555 !important;
}

.private-mode .nav-link>a {
	color: rgba(255, 255, 255, .8) !important;
}

.navbar-default {
	background-color: white;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, .1);
	border: 0;
	max-height: 60px;
	height: 60px;
}

.private-mode .navbar-default {
	background-color: #222;
	box-shadow: 0 0 12px 3px #bc13fe;
	border-bottom: 5px solid hsl(283, 99%, 90%);
	animation: flicker-large-purple 1.5s 1 alternate;
	max-height: 65px;
	height: 65px;
}

.icon-link i {
	border: 1px solid rgba(46, 92, 184, .8);
	background-color: rgba(46, 92, 184, .8);
	color: white;
}

.icon-link i:hover {
	background-color: rgba(46, 92, 184, 1);
	;
}

.private-mode .icon-link i {
	border: 1px solid rgba(255, 255, 255, .8);
	background-color: #222;
	color: white;
	box-shadow: 0 0 5px 2px rgba(22, 122, 168, .8);
	;
	animation: flicker-small-blue 1.5s 1 alternate;
}

.private-mode .icon-link i:hover {
	background-color: rgba(22, 122, 168, 1);
}

.initials {
	border: 1px solid rgba(159, 126, 206, .8);
	background-color: rgba(159, 126, 206, .8);
	color: white;
}

.initials.image {
	border: none;
}

.initials:hover {
	background-color: rgba(159, 126, 206, 1);
}

.private-mode .initials {
	border: 1px solid rgba(255, 255, 255, .8);
	background-color: #222;
	color: white;
	box-shadow: 0 0 5px 1.5px rgba(159, 126, 206, .8);
	animation: flicker-small-purple 1.5s 1 alternate;
}

.private-mode .initials:hover {
	background-color: rgba(159, 126, 206, 1);
}

.private-mode .dropdown-toggle::after {
	color: white;
}

/** Private Mode - Body **/
.content-region {
	background: linear-gradient(rgba(215, 233, 255, .75), white);
}

.private-mode .content-region {
	background: #222;
}

/** Private Mode - Cards **/

.card {
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.15);
}

.private-mode .card {
	background-color: #222;
	border: 5px solid white;
	box-shadow: 0 0 5px 2px #167aa8;
	color: white;
	border-radius: 9px !important;
}

.card.opposite-colors {
	background-color: rgba(46, 92, 184, 1);
	color: white;
}

.private-mode .card.opposite-colors {
	background-color: #167aa8;
	border: 2px solid white;
}

.card.active, .card.created {
	border: 1px solid rgba(14, 90, 14, .5);
}

.private-mode .card.active, .private-mode .card.created {
	border: 5px solid hsl(123, 100%, 90%);
	box-shadow: 0 0 5px 2px #0cff19, inset 3px 3px 3px hsl(123, 100%, 5%);
}

.card.pending, .card.requested, .card.waiting {
	border: 1px solid rgba(255, 165, 0, .5);
}

.private-mode .card.pending, .private-mode .card.requested, .private-mode .card.waiting {
	border: 5px solid hsl(39, 100%, 90%);
	box-shadow: 0 0 5px 2px #FFA700, inset 3px 3px 3px hsl(39, 100%, 5%);
}

.card.blocked {
	border: 1px solid rgba(184, 46, 46, 0.5);
}

.private-mode .card.blocked {
	border: 5px solid hsl(0, 100%, 90%);
	box-shadow: 0 0 5px 2px rgba(184, 46, 46, 1), inset 3px 3px 3px hsl(0, 100%, 5%);
}



.card .face i {
	background-color: rgb(231, 242, 255);
	color: rgb(46, 92, 184);
}

.private-mode .card .face i {
	background-color: rgb(231, 242, 255);
	color: rgb(46, 92, 184);
}

.private-mode .card.active .face i, .private-mode .card.created .face i {
	background-color: #0cff19;
	color: white;
	box-shadow: 3px 3px 3px hsl(123, 100%, 5%);
}

.private-mode .card.pending .face i, .private-mode .card.requested .face i, .private-mode .card.waiting .face i {
	background-color: #FFA700;
	color: white;
	box-shadow: 3px 3px 3px hsl(39, 100%, 5%);
}

.private-mode .card.blocked .face i {
	background-color: rgba(184, 46, 46, 1);
	color: white;
	box-shadow: 3px 3px 3px hsl(0, 100%, 5%);
}

.card .face .button {
	background-color: rgba(46, 92, 184, .6);
	color: white;
}

.private-mode .card .face .button {
	background-color: rgba(22, 122, 168, .8);
	color: white;
}

.card .face .button:hover {
	background-color: rgba(46, 92, 184, 1);
}

.private-mode .card .face .button:hover {
	background-color: rgba(22, 122, 168, 1);
}


.card .face.back {
	background-color: rgba(46, 92, 184, 1);
	color: white;
}

.card.opposite-colors .face.back {
	background-color: white;
	color: #555;
}

.card .face .details-container .row {
	border-bottom: 1px solid rgba(0, 0, 0, .05);
	color: #666;
}

.private-mode .card .face .details-container .row {
	border-bottom: 1px solid rgba(0, 0, 0, .05);
	color: rgba(255, 255, 255, .8);
}

/** Private Mode - Explore Photos **/
.quick-filters-container {
	background-color: white;
	box-shadow: 0px 1px 2px rgb(0 0 0 / 4%);
}

.private-mode .quick-filters-container {
	background-color: #222;
	box-shadow: 0px 1px 2px rgb(0 0 0 / 4%);
}

/** Private Mode - Flicer Effect **/

@keyframes flicker-large-purple {
	0%, 18%, 22%, 25%, 53%, 57%, 100% {
		box-shadow: 0 0 8px 3px #bc13fe;
	}

	20%, 24%, 55% {
		box-shadow: none;
	}
}

@keyframes flicker-small-purple {
	0%, 18%, 22%, 25%, 53%, 57%, 100% {
		box-shadow: 0 0 5px 1.5px #bc13fe;
	}

	20%, 24%, 55% {
		box-shadow: none;
	}
}

@keyframes flicker-small-blue {
	0%, 18%, 22%, 25%, 53%, 57%, 100% {
		box-shadow: 0 0 5px 2px rgba(46, 92, 184, 1);
	}

	20%, 24%, 55% {
		box-shadow: none;
	}
}

@keyframes flicker-small-green {
	0%, 18%, 22%, 25%, 53%, 57%, 100% {
		box-shadow: 0 0 5px 2px #0cff19;
	}

	20%, 24%, 55% {
		box-shadow: none;
	}
}

/** Event Page **/
.event-container .masonry-item img {
	border-radius: 4px;
}

/** Explore Page Filter Style **/
.quick-filters-container .sort-container ng-select .ng-select-container .ng-arrow-wrapper {
	margin-right: 0;
}

/** Vault - Users List **/
.filter span.even .connection-cell {
	margin-left: 5px;
	margin-right: 0;
}

.filter span .connection-cell {
	margin-right: 5px;
	margin-left: 0;
}

.filter span.even.offset .connection-cell {
	margin-right: 5px;
	margin-left: 0;
}

.filter span.offset .connection-cell {
	margin-left: 5px;
	margin-right: 0;
}

/** Welcome Widget  - Night Scene **/
.star {
	position: absolute;
	background: white;
	border-radius: 50px;
	animation: move;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

}

.type-1 {
	width: 1px;
	height: 1px;
}

.type-2 {
	width: 2px;
	height: 2px;
}

.type-3 {
	width: 3px;
	height: 3px;
}

@keyframes move {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-1000px);
	}
}

/** User Messages Widget **/

.container app-user-message .message-container {
	border-bottom: 1px dashed rgba(255, 255, 255, .5);
}

.container app-user-message:last-child .message-container {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 10px;
}

/** Expire Payment Methods Dialog on Dashboard Component **/

.modal-dialog.payment-methods .modal-content {
	width: 40vw;
}

.modal-dialog.payment-methods .modal-content .modal-header .modal-title {
	height: 36px;
	line-height: 36px;
	font-size: 1.2em;
}

.modal-dialog.payment-methods .modal-content .modal-header .modal-title i {
	font-size: .9em;
	height: 36px;
	width: 36px;
	text-align: center;
	background: #222;
	border-radius: 5px;
	color: white;
	line-height: 36px;
	vertical-align: top;
	margin-right: 5px;
}

/** This is needed for the events modals **/

.modal-backdrop {
	z-index: 999999999;
}

.modal {
	z-index: 999999999;
}

/** This is needed so the date picker shows up in modals. **/
bs-datepicker-container {
	z-index: 99999999999999;
}

/** Events Page Comments Container **/

app-event-comment:first-of-type .comment-container {
	margin-top: 0;
}

/** Profile Page - Default Settings - Dropdown CSS **/

.dropdown-container.profile .ng-select .ng-dropdown-panel {
	left: 0 !important;
}